//多规格名接口
import { axios } from '@/utils/request'

// 多规格名分页查询
export function specNamePageList(parameter){
    return axios({
        url: '/spec/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//多规格名查询所有
export function specNameList(parameter){
    return axios({
        url: '/spec/list',
        method: 'post',
        data: parameter
    })
}
//多规格名详情
export function specNameDetail(parameter){
    return axios({
        url: '/spec/detail',
        method: 'post',
        params: parameter 
    })
}
//多规格名增加
export function specNameAdd(parameter){
    return axios({
        url: '/spec/add',
        method: 'post',
        data: parameter
    })
}
//多规格名删除
export function specNameDelete(parameter){
    return axios({
        url: '/spec/delete',
        method: 'post',
        data: parameter
    })
}
//多规格名编辑
export function specNameEdit(parameter){
    return axios({
        url: '/spec/edit',
        method: 'post',
        data: parameter
    })
}