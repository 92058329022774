<template>
    <a-modal
        title="商品规格新增"
        :width="500"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="handleSubmit"
        @cancel="handleCancel"
    >
        <a-spin :spinning="confirmLoading">
            <a-row :gutter="24">
                <a-col :md="24" :sm="24">
                    <a-form :form="form">
                        <a-form-item label="商品规格" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                            <a-select
                                style="width: 100%;min-width: 250px;"
                                placeholder="请选择规格"
                                v-decorator="['specId', {rules: [{ required: true, message: '请选择规格' }]}]"
                            >
                                <a-select-option v-for="(item,index) in specList" :key="index" :value="item.id">{{ item.name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-form>
                </a-col>
            </a-row>
        </a-spin>
    </a-modal>
</template>

<script>
import { specNameList } from '@/api/modular/mallLiving/specName'
import { bindCommoditySpec } from '@/api/modular/mallLiving/commodity/commodityList'

export default {
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      orgTree: [],
      orgList: [],
      posList: [],
      sysEmpParamExtList: [],
      memberLoading: false,
      form: this.$form.createForm(this),
      data: [],
      birthdayString: [],
      specList: []
    }
  },
  methods: {
 
    // 初始化方法
    add(record) {
        this.goodsId = record.id
        this.visible = true
        this.findAllSpecList()
    },
    
    //查询所有规格
    async findAllSpecList(){
        let data = await specNameList();
        if(data.code == 200){
            this.specList = data.data
        }
    },
  
    handleSubmit() {
      const {
        form: { validateFields },
      } = this

      validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          var params = {
            specId: values.specId,
            goodsId: this.goodsId
          }
          bindCommoditySpec(params).then(res =>{
            if(res.success){
              this.$message.success('商品规格绑定成功')
              this.confirmLoading = false
              this.$emit('ok', values)
              this.visible = false
              this.handleCancel()
            }
          }).finally(err =>{
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.form.resetFields()
      this.visible = false
      // 清理子表单中数据
      this.data = []
      // 清理时间
      this.birthdayString = []
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>