<template>
  <!-- 商品列表页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <a-col :md="6" :sm="24">
                  <!-- 商品搜索 -->
                  <a-form-item label="商品搜索">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="请输入商品名称、关键字" />
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-button type="primary" @click="searchTableData">搜索</a-button>
                  <a-button
                    style="margin-left: 8px"
                    @click="
                      () => {
                        queryParam = {}
                        searchTableData()
                      }
                    "
                    >重置</a-button
                  >
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 添加商品按钮 -->
          <div class="table-operator">
            <a-button type="primary" @click="$refs.addForm.add()">添加商品</a-button>
          </div>
          <!-- 表格 -->
          <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="false">
            <!-- 商品图片插槽 -->
            <span slot="commodityImg" slot-scope="text, record">
              <img :src="record.coverImage" alt="" style="width: 68px; height: 68px; border-radius: 50%" />
            </span>

            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a-divider type="vertical" />
              <a @click="$refs.editForm.edit(record)">编辑</a>
              <a-divider type="vertical" />
              <!--竖线-->
              <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => sysItemDelete(record)">
                <a>删除</a>
              </a-popconfirm>
              <a-divider type="vertical" />
            </span>
          </a-table>
          <!-- 分页组件 -->
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-modal
      :width="1200"
      title="绑定规格"
      :visible="visible1"
      @cancel="handleCancel1"
      cancelText="关闭"
      :confirm-loading="confirmLoading1"
      :footer="null"
    >
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="$refs.addSpecForm.add(currentItem)">添加</a-button>
      </div>
      <a-table :columns="specColumns" :data-source="specDatas" :scroll="{ x: 800 }">
        <span slot="action" slot-scope="text, record">
          <a @click="showModal2(record)">添加规格属性</a>
          <a-divider type="vertical" />
          <a-popconfirm placement="topRight" title="确定删除该规格?" @confirm="() => confirmDeleteSpec(record)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </a-table>
    </a-modal>
    <a-modal
      :width="1200"
      title="规格属性"
      :visible="visible2"
      :confirm-loading="confirmLoading2"
      :footer="null"
      @cancel="handleCancel2"
    >
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="$refs.addSepcvalueForm.add(currentSpecItem, currentItem)">
          添加</a-button
        >
      </div>
      <a-table :columns="specValueColumns" :data-source="specValueDatas" :pagination="false" :scroll="{ x: 800 }">
        <a slot="name" slot-scope="text">{{ text }}</a>
        <span slot="customTitle"> <a-icon type="smile-o" /> Name </span>
        <span slot="action" slot-scope="text, record">
          <a-popconfirm
            placement="topRight"
            title="确定删除该规格属性?"
            @confirm="() => confirmDeleteSpecValue(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </span>
      </a-table>
    </a-modal>
    <add-spec-form ref="addSpecForm" @ok="handleSpecOk" />
    <add-specvalue-form ref="addSepcvalueForm" @ok="handleSpecvalueOk" />
    <detail-form ref="detailForm" />
    <add-form
      ref="addForm"
      :areaId="areaId"
      @ok="
        () => {
          this.initTableData()
        }
      "
    ></add-form>
    <edit-form
      ref="editForm"
      :areaId="areaId"
      @ok="
        () => {
          this.getList()
        }
      "
    ></edit-form>
  </div>
</template>

<script>
import {
  querySpecListByCommodityId,
  querySpecValueListBySpcId,
  deleteGoodsSpecValue,
  deleteGoodsSpec,
  platformLowerShelfGoods,
  recommendGoodPage,
  deleteRecommendGoods,
} from '@/api/modular/mallLiving/commodity/commodityList'
import addForm from './addForm.vue'

import AddSpecForm from './addSpecForm.vue'
import AddSpecvalueForm from './addSpecvalueForm'
import detailForm from './detailForm.vue'

import { findCategoryListByStore } from '@/api/modular/mallLiving/commodity/commodityClass' //查询 分类及二级分类
import editForm from './editForm.vue'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '80px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '商品名称',
    dataIndex: 'goodsName',
    key: 'goodsName',
    width: '260px',
  },
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    width: '260px',
  },

  {
    title: '操作',
    align: 'center',
    dataIndex: 'action',
    width: '160px',
    key: 'action',
    fixed: 'right',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export default {
  components: {
    editForm,
    AddSpecForm,
    AddSpecvalueForm,
    detailForm,
    addForm,
  },
  data() {
    return {
      visible1: false,
      confirmLoading1: false,
      columns,
      currentItem: null,
      currentSpecItem: null,
      data: [],
      loading: false,
      confirmLoading2: false,
      visible2: false,
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      classList: [], //顶部商品分类筛选数
      queryParam: {
        commodityClass: undefined, //商品分类
        commoditySearch: undefined, //商品搜索
      },
      specPage: {
        pageNumber: 1,
        pageSize: 10,
      },
      specValuePage: {
        pageNumber: 1,
        pageSize: 10,
      },
      specTotal: 0,
      specValueTotal: 0,
      specValueColumns: [
        {
          title: '规格属性名称',
          dataIndex: 'specValueName',
          align: 'center',
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 200,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      specColumns: [
        {
          title: '规格名称',
          dataIndex: 'specName',
          align: 'center',
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 200,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      specDatas: [],
      specValueDatas: [],
      areaId: '',
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    },
  },
  mounted() {
    console.log('this.$store.getters.userInfo', this.userInfo)
    this.areaId = this.$route.query.areaId
    this.initTableData()
    this.getCommodityClassList() //获取下拉列表分类信息
  },
  methods: {
    specPageChange(pageNo, pageSize) {
      this.specPage.pageNumber = pageNo
      this.specPage.pageSize = pageSize
      this.querySpecvalueListBySpecId()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    confirmDeleteSpecValue(record) {
      deleteGoodsSpecValue({
        id: record.goodsSpecValueId,
      }).then((res) => {
        if (res.success) {
          this.$message.success('商品规格属性删除成功')
          this.querySpecvalueListBySpecId()
        }
      })
    },
    confirmDeleteSpec(record) {
      deleteGoodsSpec({
        id: record.goodsSpecId,
      }).then((res) => {
        if (res.success) {
          this.$message.success('商品规格删除成功')
          this.findCommoditySpecList()
        }
      })
    },
    showModal2(record) {
      this.currentSpecItem = record
      this.querySpecvalueListBySpecId()
      this.visible2 = true
    },

    handleCancel1() {
      this.visible1 = false
    },

    handleCancel2(e) {
      this.visible2 = false
    },

    async querySpecvalueListBySpecId() {
      const data = await querySpecValueListBySpcId({
        goodsSpecId: this.currentSpecItem.goodsSpecId,
      })
      if (data.code == 200) {
        this.specValueDatas = data.data
      }
    },
    handleSpecvalueOk() {
      this.specValuePage.pageNumber = 1
      this.querySpecvalueListBySpecId()
    },
    handleSpecOk() {
      this.specPage.pageNumber = 1
      this.findCommoditySpecList()
    },
    showModal1(record) {
      this.currentItem = record
      this.specValuePage.pageNumber = 1
      this.findCommoditySpecList()
      this.visible1 = true
    },

    async findCommoditySpecList() {
      var params = {
        page: this.specPage,
        queryParam: this.currentItem.id,
      }
      const data = await querySpecListByCommodityId({
        goodsId: this.currentItem.id,
      })
      if (data.code == 200) {
        this.specDatas = data.data
      }
    },
    // 获取商品分类及其子列表信息
    getCommodityClassList() {
      findCategoryListByStore().then((res) => {
        //处理分类数据
        this.classList = res.data
      })
    },
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    searchTableData() {
      this.initTableData()
    },
    getList() {
      this.loading = true
      var params = {
        page: this.page,
        queryParam: { ...this.queryParam, areaId: this.areaId },
      }
      recommendGoodPage(params)
        .then((res) => {
          if (res.success) {
            this.loading = false
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
              item.banned = +item.isLowerShelf !== 1
            })
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    // 删除
    sysItemDelete(record) {
      this.recordItem = record
      this.disableCommodityCate(2) //2:删除
    },
    changeBanned(event, record) {
      let content = event ? '确定下架当前商品？' : '确定取消下架当前商品？'
      let msgContent = event ? '下架当前商品成功' : '取消下架当前商品成功'
      let errorContent = event ? '下架当前商品失败' : '取消下架当前商品失败'
      this.$confirm({
        title: '提示',
        content,
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          console.log('OK')
          platformLowerShelfGoods({ goodsId: record.id, isLowerShelf: event ? 2 : 1 })
            .then((res) => {
              if (res.success) {
                this.$message.success(msgContent)
                this.getList()
              }
            })
            .catch((error) => {
              this.$message.error(error.message || errorContent)
            })
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    },
    clickSwitch(record) {
      this.recordItem = record
    },
    changeSwitch(e) {
      let that = this
      let delFlag = e ? 1 : 3
      setTimeout(function () {
        that.disableCommodityCate(delFlag)
      }, 300)
    },
    async disableCommodityCate(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.recordItem.id,
      }
      let data = await deleteRecommendGoods(params)
      var text = delFlag == 1 ? '上架' : delFlag == 2 ? '删除' : '下架'
      if (data.code == 200) {
        this.$message.success(text + '推荐商品成功')
        this.getList()
      }
    },
    //添加商品页面路由跳转
    addCommodity() {
      this.$router.push({
        path: `/addCommodity?areaId=${this.areaId}`,
      })
    },
  },
}
</script>

<style lang="less" scoped>
// @import url(); 引入公共css
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 30px;
  height: 30px;
}
</style>
